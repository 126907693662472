@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  font-family: "EB Garamond", serif;
  color: #411924;
}

/* css */
html {
  scroll-behavior: smooth;
}

.container {
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
}

p {
  margin-bottom: 15px;
}

body.show-menu {
  overflow: hidden;
  padding-right: 15px;
}

body.show-menu .main-menu {
  /* display: block; */
  transform: translate3d(0, 0, 0);
  pointer-events: auto;
}

body.show-menu .header button svg line {
  stroke: #411924;
}

.main-menu {
  /* display: none; */
  transform: translate3d(0, -150%, 0);
  transition: transform 0.5s ease-in-out;
  pointer-events: none;
}

button {
  outline: none !important;
}

.icon-white svg path {
  fill: #ffffff;
}

.menu-container {
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 30vh;
  display: flex;
}

.hover-font-bold:hover {
  font-weight: 700;
}

.font-f-2 {
  font-family: "Assistant", sans-serif;
}

.header {
  margin-bottom: -158px;
}

@media (max-width: 767px) {
  .header-logo svg {
    width: 140px;
  }
  .header-logo {
    top: -30px;
    position: relative;
  }
}

.menu-pink line {
  stroke: #d8b5b3;
}

.header button svg {
  width: 20px;
}

.menu-arrow svg {
  width: 56px;
}

.select svg path {
  stroke: #411924;
}

body.show-reservation {
  overflow: hidden;
}

@media (min-width: 768px) {
  .header button svg {
    width: 42px;
  }
  .menu-arrow svg {
    width: 143px;
  }
  .menu-container {
    height: 570px;
  }
}

#initial-loading {
  transition-delay: 1s;
  transition: opacity 0.3s ease-in-out;
  opacity: 1;
}

.hide-loader {
  opacity: 0 !important;
  pointer-events: none;
}

.select input {
  background-color: transparent;
  outline: 0;
  width: 100%;
  cursor: pointer;
  font-family: "Assistant", sans-serif;
}

.select input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #411924;
}

.custom-datepicker .react-datepicker__triangle {
  display: none;
}

.custom-datepicker .react-datepicker-popper {
  margin-top: 11px !important;
}

.custom-datepicker .react-datepicker {
  background-color: #dcc6c4;
  color: #000;
  border-radius: 0px;
  border: 0px;
  font-size: 12px;
}

.custom-datepicker .react-datepicker__header {
  background-color: #dcc6c4;
  border: 0px;
}

.custom-datepicker .react-datepicker__day-names {
  display: none;
}

.custom-datepicker .react-datepicker__day--selected,
.custom-datepicker .react-datepicker__day--selected:hover,
.custom-datepicker .react-datepicker__day--keyboard-selected,
.custom-datepicker .react-datepicker__day--keyboard-selected:hover {
  background-color: #411924;
  border-radius: 50%;
}

.custom-datepicker .react-datepicker__day {
  margin: 5px;
}

.custom-datepicker .react-datepicker__navigation--next {
  border-left-color: #411924;
}

.custom-datepicker .react-datepicker__navigation--previous {
  border-right-color: #411924;
}

.custom-datepicker .react-datepicker__day--disabled {
  color: rgba(0, 0, 0, 0.2);
}

.custom-datepicker .react-datepicker-wrapper {
  display: block;
}

.custom-datepicker .react-datepicker__day {
  outline: none;
}
